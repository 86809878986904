.main-container{
    height: auto;
    display: grid;
    /* grid-template-columns: 10fr 2fr; */
    margin-left: 264px;
    overflow-x: hidden;
}
@media screen and (max-width:991px){
    .main-container{
        margin: 0;
    }
}