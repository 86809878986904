.topBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  /* overflow-x: hidden; */
  background: transparent;
  z-index: 10000;
  transition: all 200ms ease-in;
  background-color: #000;
}
.topBarContainer.setNavbar{
  

   
    height: 85px;
  
}


.topBarMenu {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
  justify-content: flex-end;
}
.topBarBrand h2{
  font-weight: 500;
  font-size: 1.3215rem;
  margin-left: 2rem;
}

.topBarMenu a{
  margin: 0 2px !important;
}
.SidebarMenuContainer{
position: absolute;
width: 159.37px;
height: 244px;
background: #343434;
box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
border-radius: 4.50185px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
z-index: 10000;
right: 20px;
top: 60px;
transition: all 200ms ease-in;
 /* -webkit-backdrop-filter: blur(10px); */
}


.hamburger {
    cursor: pointer;
    vertical-align: middle;
    background-color: transparent;
    width: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1.01rem;
    z-index: 10000;
}

.svgImg {
    margin-bottom: auto;
    display: block;
    height: 100%;
    width: 100%;
}

.line {
    fill: none;
    stroke: #b2c5db;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}
.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}

.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}


@media screen and (max-width:767px) {
  .topBarMenu {
    width: 20%;
  }
  .topBarMenu a{
    margin: 0 5px;
  }
}


@media screen and (min-width:992px){
  .hamburger{
    display: none;
  }
  .SidebarMenuContainer{
    display: none;
  }
  .topBarContainer{
    display: none;
  }
}
