.footer-container{
    width: 100%;
    min-height: 150px;
    /* padding-left: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
font-weight: 400;
line-height: 1.5;
/* color: #212529; */
background-color: #0d0b0fd9;
}

.footer-container a{
    text-decoration: none;
    margin-left: 5px;
    margin-right: 5px;
    color: #eee;
    font-weight: 600;

}