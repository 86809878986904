.slider-container {
  min-height: 500px;
  background-color: #2f2f2f;
}

.slider-container img {
  max-width: 700px;
  object-fit: contain;
  max-height: 400px;
}
.awssld {
  height: 90%;
}
.awssld__prev p,.awssld__next p{
  width: 60px !important;
}
.awsBtn{
    padding: 15px;
  border-radius: 50%;
  background: #6b42f8;
  opacity: 0.7;
  font-size: 20px;
  min-width: 60px !important;
  height: 60px;
}
  .awssld__bullets button {
    width: 10px !important;
    height: 12px !important;
  }
@media screen and (max-width: 1214px) {
  .slider-container img {
    max-width: 400px;
  }
}
@media screen and (max-width: 650px) {
  .slider-container img {
    max-width: 350px;
  }

}
