@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body{
  background-color: #292929;
  color: #eee;
  overflow-x: hidden;
  
}
::selection {
  color: #eee;
  background: #6b42f8;
}
html,body{
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scrollbar-color: #6b42f8 rgba(255, 255, 255, 0.5) ;
}
