.blog-container {
  width: 100%;
  min-height: 500px;
  display: grid;
  place-items: center;
  padding-bottom: 20px;
}
.btn-container {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-blog {
  outline: none;
  border: none;
  padding: 0.8rem 2.6rem;
  font-weight: 500;
  border-width: 2px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin: 1.2rem;
  border-radius: 7px;
  cursor: pointer;
  background-color: #eee;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  color: #eee;
  text-align: justify;
  word-break: break-all;
  font-size: 1.2rem;
}
.blog-head{
  width: 100%;
}
.blog1 {
  background: linear-gradient(rgba(0, 0, 0, 0.819), rgba(26, 23, 23, 0.1)),
    url("../../assets/images/ship.jpg");
  background-size: 400px 300px, cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms ease-in;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.blog2 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url("../../assets/images/com.png");
  background-size: 400px 300px, cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms ease-in;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.blog1:hover,.blog2:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.btn-primary {
  background-color: #6b42f8;
  color: #eee;
}
.btn-secondary {
  border: 2px solid #6b42f8 !important;
  background-color: transparent;
  color: #6b42f8;
}

.btn-secondary:hover {
  background-color: #6b42f8;
  color: #eee;
}

/* @media screen and (min-width:546px ) and  (max-width: 991px) {
  .btn-container {
    width: 100%;
    flex-direction: column;
  }
  .btn-blog{
    width: 200px;
    height: 200px;
    font-size: 1rem;
  }
} */

@media screen and (max-width:547px){
  .btn-container{
    width: 100%;
    flex-direction: column;
  }
    .btn-blog{
    width: 200px;
    height: 200px;
    font-size: 11px;
  }
}
