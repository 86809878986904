.sidebar-container {
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: #000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.profile-pic-section {
  display: flex;
  height: 60%;
  /* background-color: green; */
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
}
.profile {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: contain;
  display: flex;
  border: 10px solid rgba(255, 255, 255, 1);
  overflow: hidden;
position: relative;
  background-image: url('../../assets/logo/profile2.jpg'); 
  background-size: cover;
  
}
.profile-pic-section p {
  margin: 20px;
  font-size: 1.2rem;
  color: #eee;
  font-weight: 600;
  line-height: 1.8;
}
/* .profile img {
  width: 180px;
  height: 180px;
  position: absolute;

  
} */

.sidebar-menu {
  height: 40%;
  /* background-color: black; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar-menu > ul {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  list-style: none;
}

.sidebar-menu > ul li {
  width: 100%;
  height: 80%;
  text-align: center;
  display: grid;
  place-items: center;
  transition: all 200ms ease-in;
  cursor: pointer;
}
.sidebar-menu > ul li:hover {
  color: #6b42f8;
}
.sidebar-links {
  height: 20%;
  width: 100%;
  display: flex;
  /* background-color: green; */
  align-items: flex-end;
  justify-content: center;
}
.sidebar-links a {
  text-decoration: none;
  margin: 10px 2rem;
  color: #aab1b8;
  transition: all 200ms ease-in;
}
.fa-linkedin,
.fa-github,
.fa-facebook {
  color: #aab1b8;
  font-size: 2rem;

  transition: all 200ms ease-in;
}
.fa-linkedin:hover {
  color: #17a2b8;
}
.fa-github:hover {
  color: #343a40;
}
.fa-facebook:hover {
  color: #007bff;
}

@media screen and (max-width: 991px) {
  .sidebar-container {
    display: none;
  }
}
