.skill-container{
    min-height: 700px;
    background-color: #2F2F2F;
}
.skill-list,.skill-exp{
    width: 100%;
    display: flex;
    justify-content: center;
}
.skill-exp{
    flex-direction: column;
}
.skill-exp h2{
    
    text-align: center;
    /* background-color: gray; */
    width: fit-content;
    align-self: center;
    height: auto;
    position: relative;
    font-size: 2.25rem;

}
.skill-exp>h2::after{
    content: '';
    width: 70px;
    height: 5px;
    background-color: #6b42f8;
    position: absolute;
    bottom: 0;
    left: 0;
}
.skill-list ul,.skill-exp ul{
    list-style: none;
    width: 100%;
}
.skill-list li , .skill-exp li{
    font-size: 1.2rem;
    margin: 2rem;
    width: 90%;
    border-left: 3px solid #6b42f8;
    padding-left: 10px;
}
