.work-container{
  width: 100%;
  background-color: #212121;

}
.work-gallery {
  width: 100%;
  min-height: 600px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 18px;
  place-items: center;
  padding: 20px;
}

.work-gallery img {
  max-width: 150px;
  transition: all 200ms ease-in;
}
 .work-gallery-ceo{
display: flex;
width: 100%;
justify-content: center;
align-items: center;
}
 .work-gallery-img .last{
  color: inherit;
}
@media screen and (max-width: 1225px) {
  .work-gallery img {
    max-width: 150px;
  }
  .work-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
    .work-gallery .last{
   position: relative;
   left: 32vw;
  }

}

@media screen and (max-width: 576px) {
    .work-gallery img {
    max-width: 100px;
  }
}
@media screen and (max-width: 390px) {
    .work-gallery img {
    max-width: 80px;
  }
}
