*:root {
  --color: #6b42f8;
}
.about-container {
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
  padding-top: 5rem;
}

.about-info-box {
  padding: 15px;
  min-height: 800px;
  /* background-color: grey; */
  display: grid;
  grid-template-rows: 2fr 200px;
  gap: 20px;
}
.about-image{
  /* background-color: #6b42f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.about-image>img{
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 10px solid rgba(255, 255, 255, 0.5);
}

.about-info {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
  /* background-color: black; */
  min-height: 500px;
  margin-bottom: 20px;
}
.about-me {
  width: 100%;
  height: 100%;
}

h1.about-text {
  font-weight: 600px;
  font-size: 1.75rem;
  width: 50%;
  /* background-color: green; */
  margin: 30px;
}
h1.about-text span {
  color: var(--color);
}

.about-p {
  margin: 30px;
  color: rgba(255, 255, 255, 0.5) ;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.about-list {
  width: 100%;
  max-height: 70%;
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #6b42f8;
}
.about-list p{
   color: #dee3e4;
   width: 100%;
   font-weight: 600;
   text-align: center;
   padding-top: 20px;
   max-height: 50px;
   min-height: 18%;
   /* background-color: green; */
}
.about-list > ul {
  list-style: none;
  color: #dee3e4;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: blue; */
  justify-content: center;
  width: 100%;
}
.about-list > ul li {
  list-style-type: none;
  border-bottom: 1px solid #eaeaea;
  width: 80%;
  text-align: justify;
  padding: 12px;
}

.about-list > ul li > span {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  margin-right: 3px;
}
span.email {
  color: #6b42f8;
}
.about-btn {
  outline: none;
  border: none;
  padding: 0.8rem 2.6rem;
  font-weight: 500;
  border-width: 2px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin: 1.2rem;
  border-radius: 50rem;
  cursor: pointer;
  margin-top: 2rem;
}

.about-exp {
  width: 100%;
  min-height: 200px;
  /* background-color: green; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  /* padding: 20px; */
  /* margin-top: 20px; */
  /* margin-bottom: 1rem; */
}
.about-exp-year {
  text-align: center;
  width: 100%;
  padding: 20px;
}
.about-exp-year > h4 {
  font-weight: 500;
  line-height: 1.3;
  font-size: calc(1.425rem + 2.1vw);
  color: rgba(255, 255, 255, 0.5);
}
.about-exp-year > p {
  line-height: 1.8;
  color: #dee3e4;
  font-size: 1rem;
}
.about-exp-year:nth-child(1) {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 991px) {
  .about-info-box {
    min-height: 700px;
    grid-template-rows:1fr 200px;
  }
  .about-info {
    grid-template-columns: 1fr;
  }
  .about-list{
    min-height: 400px
  }
  .about-list > ul {
    max-width: 70%;
    max-height: 80%;
  }
  h1.about-text {
    width: 80%;
  }
  .about-image>img{
  width: 200px;
  height: 200px;
  }
  .about-list p{
   min-height: 18%;

  }
}
@media screen and (max-width: 505px){
    .about-list{
    min-height: 450px
  }
}
@media screen and (max-width: 405px){
    .about-list{
    min-height: 500px
  }
}

a[data-wid="wat"] a{
  display: none !important;
}