.topbarmenu-container {
  max-height: 65vh;
  min-height: 220px;
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 80px;
  background-color: rgba(0, 0, 0, 0.579);
  transition: all 200ms ease-in
}
.topbarmenu-menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: flex-end;
  align-items: center;
}
.topbarmenu-menu ul {
  width: 100%;
  display: flex;
  justify-items: flex-end;
  align-items: center;
  list-style: none;
  flex-direction: column;
  height: 100%;
}
.topbarmenu-menu li {
  width: 95%;
  height: 40px;
  border-bottom: 2px solid #eee;
  text-align: start;
  padding: 5px;
  cursor: pointer;
  transition: all 200ms ease-in

}

.topbarmenu-menu li:hover {
color: #6b42f8;
}
