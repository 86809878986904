@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
.hero-container {
  width: 100%;
  min-height: 100vh;
  background-color: #292929;
  position: relative;
  font-family: "Poppins", sans-serif;
}
.particle-hero{
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%; 
  width: 100%;
}
.hero-container img{
    height: 100% !important;
object-fit: cover !important;
width: 100% !important;
image-rendering: optimizeSpeed;
}
.hero-box {
  color: #eee;
  position: absolute;
  width: 100%;
  min-height: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  gap: 6px;
}

.hero-info-box {
  /* display: flex; */
  min-height: 210px;
  /* background-color: blue; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.hero-info-p {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  width: 100%;
}

.hero-info-name {
  line-height: 1.3;
  font-weight: 600;
  font-size: calc(1.525rem + 3.3vw);
  width: 100%;
  text-align: center;
}

.hero-info-title {
  font-size: 1.3rem;
  line-height: 1.8;
  padding: 20px;
  text-align: center;
}
.hero-btn {
  width: 100%;
  min-height: 80px;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

 .btn {
  outline: none;
  border: none;
  padding: 0.8rem 2.6rem;
  font-weight: 500;
  border-width: 2px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin: 1.2rem;
  border-radius: 50rem;
  cursor: pointer;
  /* background-color: #eee; */
  text-decoration: none;


}
.btn-primary{
  background-color: #6b42f8 ;
  color: #eee;
}
.btn-secondary{
  border: 2px solid #6b42f8 !important;
  background-color: transparent;
  color: #6b42f8;

}


.btn-secondary:hover{
    background-color: #6b42f8;
    color: #eee;
}



@media screen and (max-width:768px){
    .hero-btn{
        flex-direction: column;
    }
}


.hero-container .video-bg,.hero-mask{
 position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #1a1f25;
}
.hero-mask{
    background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
.video-bg video{
    width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}


ParallaxVideo{ 
    height: 300px;
    padding-bottom: 50px;
    padding-top: 50px; 
}
.ParallaxVideo video{ 
    min-width: 100%;
    position: fixed;
    top:0;
    z-index: -999;
}
.ParallaxVideo h1 {
  color: #fff;
  font-size: 76px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
