.contact-container {
  width: 100%;
  min-height: 700px;
  background-color: rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.contact-box {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.contact-details {
  max-height: 65%;
  /* background-color: green; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding-left: 70px;
}
.contact-me {
  width: 100%;
  height: 75%;
  display: grid;
  place-items: start;
  padding-left: 40px;
}
.follow {
  display: grid;
  place-items: start;
  padding-left: 40px;
  width: 100%;
  /* background-color: black; */
  height: auto;
}
.contact-me p i {
  margin-right: 5px;
  color: #6b42f8;
}
.follow-links{
  margin-top: 5px;
}

.follow .follow-links a i {
  margin-right: 20px;
}
.fa-linkedin,
.fa-github,
.fa-facebook {
  color: #aab1b8;

  transition: all 200ms ease-in;
}
.fa-linkedin:hover {
  color: #17a2b8;
}
.fa-github:hover {
  color: rgba(255, 255, 255, 0.08);
}
.fa-facebook:hover {
  color: #007bff;
}
.contact-form {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  padding-top: 0;
  /* background-color: blue; */
}

.contact-form h2 {
  height: 10%;
  width: 100%;
  
}
.contact-form form {
  height: 90%;
  display: grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 14px;
  width: 75%;
  place-items: center;
}

.contact-form form input[type="text"],
.contact-form form input[type="email"],
.contact-form form input[type="tel"],
.contact-form form textarea {
  border: none;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.12);
  color: white;
  padding: 20px;
  outline: none;
  caret-color: #6b42f8;
}
.contact-form form input[type="text"]:focus,
.contact-form form input[type="email"]:focus,
.contact-form form input[type="tel"]:focus,
.contact-form form textarea:focus {
  border: 1px solid #6b42f8;
}
.contact-form form input[type="text"] {
  grid-area: 1 / 1 / 2 / 3;
  height: 60%;
  width: 100%;
}
.contact-form form input[type="email"] {
  grid-area: 1 / 3 / 2 / 5;
  height: 60%;
  width: 100%;
}
.contact-form form input[type="tel"] {
  grid-area: 2 / 1 / 3 / 5;
  height: 60%;
  width: 100%;
}
.contact-form form textarea {
  grid-area: 3 / 1 / 5 / 5;
  height: 100%;
  width: 100%;
}
.qr-code{
  display: flex;
  height: 150px;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .contact-box {
    flex-direction: column-reverse;
  }

  .contact-form{
    width: 100%;
    align-items: center;
  }
  .contact-me,
  .follow {
    width: 100%;
    margin: 10px;
    padding: 0;
  }
  .contact-me h2 {
    font-size: 1.2rem;
  }
  .contact-form h2{
    width: 100%;
    text-align: center;
  }
.contact-form form{
  width: 90%;
}
  .contact-me p {
    margin: 2px;
  }
  .contact-form {
    width: 100%;
  }
}
@media screen and (max-width: 768px) and (min-width: 525px) {
  .contact-details {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
  .contact-me,
  .follow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .contact-me,
  .follow{
    width: 100%;
  }
}
@media screen and (max-width: 524px){
  .contact-details{
    flex-direction: column;
    width: 100%;
  }

  
}

#form a{
  display: none !important;
}

