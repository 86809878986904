*:root{
    --color:#6B42F8;
}

.title-container{
    width: auto;
    height: 300px;
    text-align: center;
    position: relative;
    /* background-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-container>h1{
    color: #8e9a9d !important;
    opacity: 0.1;
    font-weight: 600;
    line-height: 1.3;
    font-size: calc(1.95rem + 8.4vw);
    width:100%
}
.title-container>h3{
    position: absolute;
    font-weight: 600;
    font-size: 2.25rem;
top: 45%;
}
.title-container>h3::after{
    content: '';
    width: 70px;
    height: 5px;
    background-color: var(--color);
    position: absolute;
    bottom: 0;
    left: 0;
}